import React from 'react'
import css from './TabLoader.module.css'
import classNames from 'classnames'

function TabLoader() {
  return (
    <section className={css.lazyloaderLayout}>
      <div className={classNames(css.cardBox, css.cardBoxMargin)}>
        <div className={css.cardColumn}>
          <div className={css.cardIcon} />
          <div className={css.cardTitle} />
          <div className={css.cardTitle} />
        </div>
        <div className={css.cardColumn}>
          <div className={css.cardIcon} />
          <div className={css.cardTitle} />
          <div className={css.cardTitle} />
        </div>
        <div className={css.cardColumn}>
          <div className={css.cardIcon} />
          <div className={css.cardTitle} />
          <div className={css.cardTitle} />
        </div>
        <div className={css.cardColumn}>
          <div className={css.cardIcon} />
          <div className={css.cardTitle} />
          <div className={css.cardTitle} />
        </div>
      </div>
      <div className={css.bottomLayout}>
        <div className={css.smallCard}>
          <div className={css.straightLine} />
          <div className={css.straightLine} />
          <div className={css.straightLine} />
          <div className={css.straightLine} />
        </div>
        <div className={css.bigCard}>
          <div className={css.bigcardHeading} />
          <div className={css.bigcardSubHeading} />
          <div className={css.bigcardDescription} />
          <div className={css.bigcardLink} />
        </div>
      </div>
    </section>
  )
}

export default TabLoader