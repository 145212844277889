import React, { useEffect, useState } from 'react';
import PropTypes, { number, shape } from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TermsModal,
  TermsOfUse,
  LandingPageTabsPanel,
  Button,
  ExternalLink,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  approvedUserStatus,
  fetchUserRole,
  getUserRole,
  userTncVersion,
} from '../../util/userRole';
import facebookImage from '../../assets/insightGigFacebook-1200x630.jpg';
import twitterImage from '../../assets/insightGigTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import moment from 'moment';
import { withViewport } from '../../util/contextHelpers';
import { checkScrollingDisabled } from './LandingPage.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  freePlan,
  freePlanObject,
  getUserDetails,
  isEmailVerified,
  isUserLinkedToFirm,
  offlineSubscriptionObject,
  userSubscriptions,
} from '../../util/destructorHelpers';
import { USER_ROLE_CLIENT } from '../../util/types';
import TabLoader from './TabLoader.js';

export const LandingPageComponent = props => {
  const { history, intl, location, viewport, params } = props;
  const state = useSelector(state => state);
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { updateInProgress, updateProfileError } = useSelector(state => state.ProfileSettingsPage);
  const isScrollingDisabled = useSelector(state => checkScrollingDisabled(state));
  const dispatch = useDispatch();
  // const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [showTabs, setShowTabs] = useState(false);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const isApprovedUser = currentUser?.id && approvedUserStatus(currentUser);
  const tncVersion = config && config.tncVersion;
  const isTncVersionSame = tncVersion == userTncVersion(currentUser);
  const hasUserAcceptTnc =
    currentUser?.id && !!currentUser?.attributes?.profile?.protectedData?.tncAcceptanceDate;

  const userRole = getUserRole(currentUser);
  const isRole = !!fetchUserRole(currentUser);
  const userEmail = getUserDetails(currentUser)?.email;
  const fullName = getUserDetails(currentUser)?.fullName;
  const emailVerified = isEmailVerified(currentUser);
  const offlineSubscriptionData = (currentUser?.id && offlineSubscriptionObject(currentUser)) || {};
  const onlineSubscriptionData =
    currentUser?.id && userSubscriptions(currentUser)?.find(sub => sub.plan)?.plan;
  const hasSubscription = !!(offlineSubscriptionData?.planId || onlineSubscriptionData?.id);
  const isFreePlanUsed = currentUser?.id && freePlan(currentUser);
  const checkIfUserLinkedToFirm = currentUser?.id && isUserLinkedToFirm(currentUser);

  // SetTimeout to delay the tabs loading by 800ms 
  useEffect(() => {
    const myTimeout = setTimeout(() => setShowTabs(true), 800)

    return () => clearInterval(myTimeout);
  }, []);

  useEffect(() => {
    if (isAuthenticated && currentUser?.id) {
      if (!hasUserAcceptTnc || !isTncVersionSame) {
        setShow(true);
      }
    }
  }, [hasUserAcceptTnc, isTncVersionSame]);

  const handleAgreeTnc = async () => {
    const profile = {
      protectedData: {
        tncVersion,
        tncAcceptanceDate: moment().format('YYYY-MM-DD hh:mm:ss'),
      },
    };
    const response = await dispatch(updateProfile(profile));

    if (response?.data) {
      setShow(false);
    }
  };

  useEffect(() => {
    // Check if the current user is logged in.
    if (!currentUser?.id) {
      return;
    }

    // Create a new profile object.
    const profile = {};

    // Update the profile object with the user's public data, if necessary.
    if (!isRole && userRole) {
      profile.publicData = {
        ...profile.publicData,
        role: userRole,
        fullName,
        email: userEmail,
      };
    }

    if (!emailVerified) {
      profile.publicData = {
        ...profile.publicData,
        emailVerified: true,
      };
    }

    // Update the profile object with the user's subscription data, if necessary.
    if (
      !isFreePlanUsed &&
      !hasSubscription &&
      userRole === USER_ROLE_CLIENT &&
      !checkIfUserLinkedToFirm
    ) {
      profile.publicData = {
        ...profile.publicData,
        freePlanData: freePlanObject,
      };
    }

    // Update the user's profile, if necessary.
    if (Object.keys(profile).length > 0) {
      dispatch(updateProfile(profile));
    }
  }, [currentUser?.id, dispatch, userRole, userEmail]);
  

  return (
    <Page
      className={css.root}
      scrollingDisabled={isScrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer showRoleSwitcher />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {!!currentUser?.id && !isApprovedUser && (
            <p className={css.nonApprovedUserInfo}>
              <FormattedMessage id="LandingPage.nonApprovedUserInfo" />
              <ExternalLink href="mailto:admin@insightgig.com" className={css.nonApprovedUserInfoLink}>
                <FormattedMessage id="LandingPage.nonApprovedUserInfoLink" />
              </ExternalLink>
              .
            </p>
          )}
          <div className={css.mainWrapper}>
            <div className={css.wrapperBox}>
              <div className={css.mainTitles}>
                <h1 className={css.welcomeTitle}>Welcome to InsightGig</h1>
                <p className={css.welcomeSubTitle}>What would you like to do today?</p>
              </div>
              {showTabs ? (
                <LandingPageTabsPanel
                  currentUser={currentUser}
                  location={location}
                  history={history}
                  intl={intl}
                  params={params}
                  isApprovedUser={isApprovedUser}
                />
              ) : (
                <div className={css.loaderWrapper}>
                  <TabLoader />
                </div>
              )}
            </div>
          </div>
          <TermsModal
            id="LandingPage.TermsModal"
            className={css.disableModalBorder}
            containerClasses={css.containerClassName}
            isOpen={show}
            onClose={() => { }}
            onManageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
            closeButtonMessage={' '}
          >
            <h2 className={css.termsHeading}>
              <FormattedMessage id="LandingPage.termsHeading" />
            </h2>
            <h4 className={css.termsPara}></h4>
            {updateProfileError || !isTncVersionSame ? (
              <h4 className={css.termsPara}>
                <FormattedMessage id="LandingPage.termsPara" />
              </h4>
            ) : null}
            {/* <Button onClick={() => setToggle(!toggle)}>{toggle ? "Hide" : "Show"}</Button> */}
            <div className={css.termsWrapper}>
              <TermsOfUse className={css.termsBox} />
              <Button onClick={handleAgreeTnc} inProgress={updateInProgress}>
                <FormattedMessage id="LandingPage.agreeButton" />
              </Button>
            </div>
          </TermsModal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, withViewport, injectIntl)(LandingPageComponent);

export default LandingPage;
